<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron revisiones"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idpresupuestocli_mant"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <presupuestocli-mant-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :has-perm-borrar="presupuestoPendiente && hasDeletePerm(permissions.presupuestocliMant.id)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './PresupuestocliMantListData'
import PresupuestocliMantListItem from './components/PresupuestocliMantListItem'
import { get } from 'vuex-pathify'
import { PRESUPUESTOCLI } from '@/utils/consts'

const pageStoreName = 'pagesPresupuestocliMantList'

export default {
  components: {
    PresupuestocliMantListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      presupuestocli: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    presupuestoPendiente () {
      return (
        this.presupuestocli &&
        PRESUPUESTOCLI.estadosPendientes.includes(this.presupuestocli.idest_presupuestocli)
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Revisiones de presupuestos'
      const resp = await Data.selectLoadPage(this, this.routeParams.idpresupuestocli)
      this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPresupuestocliMant(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idpresupuestocli
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectPresupuestocliMantRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idpresupuestocli_mant')
      } finally {
        this.loadingData = false
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.periodoRevision.items.length === 0 &&
        this.filter.tactuacion.items.length === 0
      ) {
        const resp = await Data.selectLookups(this)
        await this.setStoreProperty('filter@periodoRevision.items', resp.data.selectPeriodoRevision.result.dataset)
        await this.setStoreProperty('filter@tactuacion.items', resp.data.selectTactuacion.result.dataset)
      }
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar la revision "${data.item.descripcion}"?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deletePresupuestocliMant(this, data.item.idpresupuestocli_mant)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  },
}
</script>
